import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../../domain/error/error-message.model';
import { ClickLog } from './click.models';

export const saveClick = createAction(
	'[Click] SaveClick',
	props<{
		clickTo: string;
		action?: string;
		context?: { contextID: number; contextTypeID: number };
	}>()
);
export const saveClickSuccess = createAction('[Click] SaveClickSuccess');
export const saveClickFailure = createAction(
	'[Click] SaveClickFailure',
	props<{ error: ErrorMessage }>()
);

export const saveClickLog = createAction('[Click] SaveClickLog', props<{ clickLog: ClickLog }>());
export const saveClickLogSuccess = createAction('[Click] SaveClickLogSuccess');
export const saveClickLogFailure = createAction(
	'[Click] SaveClickLogFailure',
	props<{ error: ErrorMessage }>()
);
